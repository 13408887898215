














































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetZhenciList,
  LeftInterviews,
  GetTemplates,
  GetContents,
  DeleteZhenci,
  AddZhenci,
  PostContents,
  TijiaoShenhe,
  getTongue,
  Shenhetongguo,
  PostRelive,
  PostPhoneInvalid,
  ImportSizhen,
  getPulseAnalysisData,
  EditZhenciTime,
  Repulse,
  ResetContent,
} from "@/request/storehouse";
import { GetCurrentUserData } from "@/request/account";
import {
  GetZhenciEyeData,
  GetZhenciPalmData,
  UploadEye,
  UploadPalm,
  EditEyeImg,
  EditPalmImg,
} from "@/request/research";
import { getFaceAnalysisData, PostFaceAnalysisData } from "@/request/common";
import {
  IfEmpty,
  hideContent,
} from "@/components/template-transfer-pc/template-transfer/common";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import Custom from "@/components/template-transfer-pc/template-transfer/custom.vue";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
import She from "@/components/template-transfer-pc/template-transfer/she/she.vue";
import Mian from "@/components/template-transfer-pc/template-transfer/mian/mian.vue";
import Mai from "@/components/template-transfer-pc/template-transfer/mai/mai.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
import ComposePatient from "../../components/compose-patient/compose-patient.vue";
import JianchahenjiDialog from "../../components/jianchahenji-dialog.vue";
import XitongduijieYiyuan from "../xitongduijie-yiyuan/xitongduijie.vue";
import XitongduijieShujuchi from "../xitongduijie-shujuchi/xitongduijie.vue";
import leftNav from "@/components/left-nav/left-nav.vue";
@Component({
  components: {
    leftNav,
    XitongduijieYiyuan,
    XitongduijieShujuchi,
    MainHeader,
    Empty,
    Custom,
    TemplateTransfer,
    She,
    Mian,
    Mai,
    LeaveConfirm,
    ComposePatient,
    JianchahenjiDialog,
  },
})
export default class Name extends Vue {
  private redIndex: any = -1; // 需要标红提示未填的index
  private source: any = "";
  private filter: any = {
    showXitongshuju: false,
    currentXitong: "中医影像",
    showList: false,
    isShow: true,
  };
  private zhenci_id: any = "";
  private zhencis: any = [];
  private cZhenci: any = {};
  private interviews: any = [];
  private cTemplate: any = {};
  private target: any = {}; // 诊次切换时要切换的诊次数据，或者量表切换时的量表数据
  private tuMsg: any = {};
  private templates: any[] = [];
  // private cContent: any = {};
  private showNav: any = true;
  private ifShowNavChangeConfirm: boolean = false;
  private ifShowZhenciChangeConfirm: boolean = false;
  private ifShowComposePatient: boolean = false;
  private saveConfirmType: any = "";
  private ifShowSaveConfirm: boolean = false;
  private ifShowXitongduijie: boolean = false;
  private ifShowShujuchi: boolean = false;
  private noSaveText: any = "不保存，切换";
  private ifShowJiancha: any = false;
  private ifShow: any = true;
  private clickBtn: any = "";
  private editData: any = {}; // 修改诊次时间存储的操作的诊次信息
  private time: any = "";
  private ifShowTime: any = false;
  private get center() {
    return this.$store.state.center;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get ifZhiyi() {
    return this.$store.state.ifZhiyi;
  }
  private get canRead() {
    if (this.cContent.content_id) {
      return true;
    } else {
      if (
        (this.cTemplate["名称"] === "舌图数据" ||
          this.cTemplate["名称"] === "面图数据" ||
          this.cTemplate["名称"] === "目图数据" ||
          this.cTemplate["名称"] === "掌图数据" ||
          this.cTemplate["名称"] === "手掌数据" ||
          this.cTemplate["名称"] === "脉图数据") &&
        this.cContent["检测时间"]
      ) {
        return true;
      }
      if (
        this.user["role"]["名称"] &&
        (this.user["role"]["名称"] === "研究者" ||
          this.user["role"]["名称"] === "主要研究者")
      ) {
        if (this.cZhenci["status"] === "草稿") {
          return true;
        }
        if (
          this.cZhenci["status"] === "待审核" &&
          this.cZhenci.type !== "电话访视"
        ) {
          if (
            this.cZhenci.audit_process &&
            this.cZhenci.audit_process.length > 1 &&
            this.cZhenci.audit_process[this.cZhenci.audit_process.length - 2][
              "status"
            ] === "质量审核员已打回"
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    }
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get cContent() {
    return this.$store.state.cContent;
  }
  private changeToCaogao() {
    this.$confirm(
      "您确定需要将当前访视数据打回草稿么？打回后当前的质疑痕迹将全部清空。",
      "打回草稿",
      {
        customClass: "commonConfirm",
      }
    ).then(() => {
      const params: any = {
        zhenci_id: this.cZhenci.zhenci_id,
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      Repulse(this, params, loading).then((data: any) => {
        this.$message.success("打回成功");
        this.$store.commit("updateIfCompose", false);
        this.refreshData();
      });
    });
  }
  private updateNingboMenzhen(e: any) {
    // label量表显示字段，name对接后端返回字段
    let keyArr: any = [];
    if (this.cTemplate["名称"] == "门诊数据") {
      keyArr = [
        {
          label: "门诊号",
          name: "门诊号",
        },
        {
          label: "门诊时间",
          name: "就诊日期",
        },
        {
          label: "西医诊断",
          name: "西医诊断",
        },
        {
          label: "中医疾病",
          name: "中医疾病",
        },
        {
          label: "中医证型",
          name: "中医证型",
        },
        {
          label: "治疗计划",
          name: "治疗计划",
        },
      ];
    } else if (this.cTemplate["名称"] == "住院数据") {
      keyArr = [
        {
          label: "住院号",
          name: "住院号",
        },
        {
          label: "入院时间",
          name: "入院时间",
        },
        {
          label: "出院时间",
          name: "出院时间",
        },
        {
          label: "主诉",
          name: "主诉",
        },
        {
          label: "病史特点",
          name: "病史特点",
        },
        {
          label: "四诊摘要",
          name: "四诊摘要",
        },
      ];
    }
    const data = JSON.parse(JSON.stringify(this.cContent.data));
    data.forEach((element: any) => {
      keyArr.forEach((ele: any) => {
        if (ele.label == element.title) {
          element.value = e[ele.name];
          this.$forceUpdate();
        }
      });
    });
    this.cContent.data = data;
    this.$forceUpdate();
  }
  private upload(file: any, key: any) {
    if (!this.ifCompose) {
      this.$message.warning("请点击编辑按钮开始编辑");
      return;
    }
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UploadFile(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.cContent[key].push(res.full_file_url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private preview(url: any) {
    window.open(url);
  }
  private del(index: any, key: any) {
    this.cContent[key].splice(index, 1);
  }
  private handleCommand(e: any) {
    if (e.type == "del") {
      this.deleteZhenci(e.command);
    } else {
      this.editData = e.command;
      this.time = e.command.create_time;
      this.ifShowTime = true;
    }
  }
  private sureTime() {
    const params: any = {
      project_id: this.editData.project_id,
      patient_id: this.editData.patient_id,
      zhenci_id: this.editData.zhenci_id,
      create_time: this.time,
    };
    EditZhenciTime(this, params).then((res: any) => {
      this.$message.success("修改成功！");
      this.ifShowTime = false;
      this.getZhenciList();
    });
  }
  private openDuijie() {
    this.ifShowXitongduijie = true;
  }
  private changeIfShow(e: any) {
    this.ifShowXitongduijie = e;
  }
  private changeIfShowShujuchi(e: any) {
    this.ifShowShujuchi = e;
  }
  private closeDuijie() {
    this.ifShowXitongduijie = false;
  }
  /**
   * @description 打开监查痕迹
   */
  private openJianchahenji() {
    this.ifShowJiancha = true;
  }
  /**
   * @description 打开编辑患者信息弹窗
   */
  private openComposeDialog() {
    this.$store.commit("updateIfCompose", false);
    this.ifShowComposePatient = true;
  }
  /**
   * @description 关闭编辑患者信息弹窗
   */
  private composeCancel() {
    this.ifShowComposePatient = false;
  }
  /**
   * @description 隐藏或者显示某些相关题目
   */
  private changeHide(e: any, index: any) {
    const data = this.cContent.data;
    hideContent(e, data);
  }
  /**
   * @description 导入影像数据到诊次
   */
  private saasImportData(e: any) {
    if (e["报告名称"] !== this.cTemplate["名称"] + "采集报告") {
      this.$message.warning("当前量表不能匹配导入！");
      return;
    }
    const params = {
      report_id: e.id,
      type: e["报告名称"],
      patient_id: this.patient.patient_id,
      project_id: this.center.project_id,
      zhenci_id: this.cZhenci.zhenci_id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    ImportSizhen(this, params).then((res: any) => {
      this.$message.success("导入成功！");
      loading.close();
      this.fetchTemplate();
      // if (this.cTemplate["名称"] === "舌图数据") {
      //   (this.$refs.shetu as any).partChange();
      // } else if (this.cTemplate["名称"] === "面图数据") {
      //   (this.$refs.miantu as any).fetchInfo();
      // } else if (this.cTemplate["名称"] === "脉图数据") {
      //   (this.$refs.maitu as any).fetchInfo();
      // }
    });
  }
  /**
   * @description 曙光导入LIS系统数据到辅助检查
   */
  private shuguangImportData(e: any) {
    if (this.cContent.data.length === 0) {
      const d = this.cContent;
      d.data.push(e);
      // this.$set(this, "cContent", d);
      this.$store.commit("updateCContent", d);
    } else {
      let hasRepeat = false;
      this.cContent.data.forEach((ele: any) => {
        if (ele.id === e.id) {
          hasRepeat = true;
        }
      });
      if (hasRepeat) {
        this.$message.success("已添加，不能重复添加！");
      } else {
        const d = this.cContent;
        d.data.push(e);
        // this.$set(this, "cContent", d);
        this.$store.commit("updateCContent", d);
      }
    }
  }
  /**
   * @description 宁波导入LIS系统数据到辅助检查
   */
  private ningboImportData(e: any) {
    if (this.cContent.data.length === 0) {
      const d = this.cContent;
      d.data.push(e);
      // this.$set(this, "cContent", d);
      this.$store.commit("updateCContent", d);
    } else {
      let hasRepeat = false;
      this.cContent.data.forEach((ele: any) => {
        if (ele.id === e.id) {
          hasRepeat = true;
        }
      });
      if (hasRepeat) {
        this.$message.success("已添加，不能重复添加！");
      } else {
        const d = this.cContent;
        d.data.push(e);
        // this.$set(this, "cContent", d);
        this.$store.commit("updateCContent", d);
      }
    }
  }
  private jumpBiaozhu() {
    this.$router.push({
      path: "/main/research/tongue/biaozhu",
      query: {
        tongue_id: this.cContent.tongue_id,
        project_id: this.cContent.project_id,
      },
    });
  }
  private jumpBiaozhuEye() {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: this.cContent.eye_id,
        project_id: this.cContent.project_id,
      },
    });
  }
  private jumpBiaozhuPulse() {
    this.$router.push({
      path: "/main/research/pulse/biaozhu",
      query: {
        id: this.cContent.pulse_id,
        project_id: this.cContent.project_id,
        source_type: this.cContent.source_type,
      },
    });
  }
  private jumpBiaozhuPalm() {
    this.$router.push({
      path: "/main/research/hand/biaozhu",
      query: {
        id: this.cContent.palm_id,
        project_id: this.cContent.project_id,
      },
    });
  }
  private goBack() {
    if (this.ifCompose) {
      this.saveConfirmType = "离开";
      this.noSaveText = "不保存，离开";
      this.ifShowSaveConfirm = true;
    } else {
      this.$router.back();
    }
  }
  // 保存确认弹框保存
  private changeSave() {
    this.ifShowSaveConfirm = false;
    if (this.saveConfirmType === "诊次") {
      this.save().then(() => {
        this.cZhenci = JSON.parse(JSON.stringify(this.target));
        this.$store.commit("updateCZhenci", this.cZhenci);
        this.cTemplate = {};
        this.$store.commit("updateCTemplate", this.cTemplate);
        this.target = {};
        this.$store.commit("updateIfCompose", false);
        this.getLeftInterviews().then(() => {
          this.selectBingli();
        });
      });
    } else if (this.saveConfirmType === "量表") {
      this.save().then(() => {
        this.cTemplate = JSON.parse(JSON.stringify(this.target));
        this.$store.commit("updateCTemplate", this.cTemplate);
        this.target = {};
        this.$store.commit("updateIfCompose", false);
        this.fetchTemplate();
      });
    } else if (this.saveConfirmType === "离开") {
      this.save().then(() => {
        this.$router.back();
      });
    }
  }
  // 保存确认弹框不保存,切换
  private changeConfirmed() {
    this.ifShowSaveConfirm = false;
    if (this.saveConfirmType === "诊次") {
      this.cZhenci = JSON.parse(JSON.stringify(this.target));
      this.$store.commit("updateCZhenci", this.cZhenci);
      this.cTemplate = {};
      this.$store.commit("updateCTemplate", this.cTemplate);
      this.target = {};
      this.$store.commit("updateIfCompose", false);
      this.getLeftInterviews().then(() => {
        this.selectBingli();
      });
    } else if (this.saveConfirmType === "量表") {
      this.cTemplate = JSON.parse(JSON.stringify(this.target));
      this.$store.commit("updateCTemplate", this.cTemplate);
      this.target = {};
      this.$store.commit("updateIfCompose", false);
      this.fetchTemplate();
    } else if (this.saveConfirmType === "离开") {
      this.$store.commit("updateIfCompose", false);
      this.$router.back();
    }
  }
  /**
   * @description 删除诊次
   */
  private deleteZhenci(zhenci: any) {
    this.$confirm("您是否确定删除？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            zhenci_id: zhenci["zhenci_id"],
          },
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        DeleteZhenci(this, params)
          .then((data: any) => {
            loading.close();
            this.$message.success("删除成功");
            this.getZhenciList().then(() => {
              if (this.zhencis.length > 0) {
                this.cZhenci = this.zhencis[0];
                this.$store.commit("updateCZhenci", this.cZhenci);
                this.$router.replace({
                  path: "/main/storehouse/case/detail",
                  query: {
                    id: this.cZhenci.zhenci_id,
                  },
                });
                this.cTemplate = {};
                this.$store.commit("updateCTemplate", this.cTemplate);
                this.$store.commit("updateCContent", {});
                this.getLeftInterviews().then(() => {
                  this.selectBingli();
                });
              } else {
                this.interviews = [];
                this.ifShow = false;
              }
            });
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  /**
   * @description 快速定位到病例
   */
  private selectBingli() {
    if (!this.interviews) {
      return;
    }
    this.selectTemplate(this.interviews[0]["templates"][0]);
    this.$store.commit("updateIfZhiyi", false);
  }
  /**
   * @description 添加诊次
   */
  private addZhenci() {
    return new Promise((resolve, reject) => {
      const params: any = {
        project_id: this.center.project_id,
        group_id: this.center.group_id,
        patient_id: this.patient.patient_id,
        type: "0",
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      AddZhenci(this, params)
        .then((data: any) => {
          loading.close();
          this.zhenci_id = data.zhenci_id;
          resolve(data);
        })
        .catch(() => {
          loading.close();
          reject();
        });
    });
  }
  /**
   * @description 添加诊次按钮
   */
  private tianjiaZhenci() {
    if (this.ifCompose) {
      this.$message.warning("请先保存当前量表信息！");
      return;
    }
    this.addZhenci().then(() => {
      this.getZhenciList().then(() => {
        this.$router.replace({
          path: "/main/storehouse/case/detail",
          query: {
            id: this.zhencis[this.zhencis.length - 1].zhenci_id,
          },
        });
        this.cZhenci = this.zhencis[this.zhencis.length - 1];
        this.$store.commit("updateCZhenci", this.cZhenci);
        this.cTemplate = {};
        this.$store.commit("updateCTemplate", this.cTemplate);
        this.$store.commit("updateCContent", {});
        this.getLeftInterviews().then(() => {
          this.clickBtn = "添加诊次";
          this.selectBingli();
        });
      });
    });
  }
  /**
   * @description 开始编辑
   */
  private startCompose() {
    if (this.cTemplate.template_id) {
      if (this.cTemplate["名称"] === "舌图数据") {
        this.$message.warning("舌图数据不可编辑");
        return;
      }
      if (this.cTemplate["名称"] === "脉图数据") {
        this.$message.warning("脉图数据不可编辑");
        return;
      }
      this.$store.commit("updateIfCompose", true);
    } else {
      // this.$message.warning("请先选择一个量表");
    }
  }
  /**
   * @description 保存量表
   */
  private saveContent() {
    this.save().then(() => {
      this.getLeftInterviews().then(() => {
        this.fetchTemplate();
      });
    });
  }
  /**
   * @description 保存
   */
  private save() {
    return new Promise((resolve, reject) => {
      if (this.cTemplate["名称"] === "面图数据") {
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        PostFaceAnalysisData(this, this.cContent)
          .then((data: any) => {
            loading.close();
            this.$message.success("保存成功");
            this.$store.commit("updateIfCompose", false);
            this.fetchTemplate();
            resolve(this.cContent);
          })
          .catch(() => {
            loading.close();
            reject(this.cContent);
          });
      } else if (this.cTemplate["名称"] === "目图数据") {
        if (
          this.cContent.l_eye_image.length == 0 &&
          this.cContent.r_eye_image.length == 0
        ) {
          this.$message.warning("请上传目图");
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        if (!this.cContent.eye_id) {
          const params: any = {
            source: this.cContent.source,
            project_id: this.cContent.project_id,
            source_type: this.cContent.source_type,
            patient_id: this.cContent.patient_id,
            zhenci_id: this.cContent.zhenci_id,
            l_eye_image: this.cContent.l_eye_image,
            r_eye_image: this.cContent.r_eye_image,
          };
          UploadEye(this, params, loading)
            .then((res: any) => {
              loading.close();
              this.$message.success("保存成功");
              this.$store.commit("updateIfCompose", false);
              this.fetchTemplate();
              resolve(this.cContent);
            })
            .catch(() => {
              loading.close();
              reject(this.cContent);
            });
        } else {
          EditEyeImg(this, this.cContent, loading)
            .then((data: any) => {
              loading.close();
              this.$message.success("保存成功");
              this.$store.commit("updateIfCompose", false);
              this.fetchTemplate();
              resolve(this.cContent);
            })
            .catch(() => {
              loading.close();
              reject(this.cContent);
            });
        }
      } else if (this.cTemplate["名称"] === "手掌数据") {
        if (
          this.cContent.l_palm_image.length == 0 &&
          this.cContent.r_palm_image.length == 0
        ) {
          this.$message.warning("请上传手掌");
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        if (!this.cContent.palm_id) {
          const params: any = {
            source: this.cContent.source,
            project_id: this.cContent.project_id,
            source_type: this.cContent.source_type,
            patient_id: this.cContent.patient_id,
            zhenci_id: this.cContent.zhenci_id,
            l_palm_image: this.cContent.l_palm_image,
            r_palm_image: this.cContent.r_palm_image,
          };
          UploadPalm(this, params, loading)
            .then((res: any) => {
              loading.close();
              this.$message.success("保存成功");
              this.$store.commit("updateIfCompose", false);
              this.fetchTemplate();
              resolve(this.cContent);
            })
            .catch(() => {
              loading.close();
              reject(this.cContent);
            });
        } else {
          EditPalmImg(this, this.cContent, loading)
            .then((data: any) => {
              loading.close();
              this.$message.success("保存成功");
              this.$store.commit("updateIfCompose", false);
              this.fetchTemplate();
              resolve(this.cContent);
            })
            .catch(() => {
              loading.close();
              reject(this.cContent);
            });
        }
      } else {
        // 判断必填项是否填完
        for (let i = 0; i < this.cContent.data.length; i++) {
          const weitian = IfEmpty(this.cContent.data[i]);
          if (weitian) {
            // 弹提示哪个未填,并用红框框上
            this.$message.warning(this.cContent.data[i].title + "为必填项！");
            this.redIndex = i;
            // 滚动到没填的题目
            const parent = (document as any).getElementById("main-content");
            const child = (document as any).getElementById("liangbiao" + i);
            parent.scrollTop = child.offsetTop - parent.offsetTop;
            return;
          }
        }
        const params: any = {
          patient_id: this.patient.patient_id,
          project_id: this.center.project_id,
          group_id: this.center.group_id,
          template_id: this.cTemplate.template_id,
          zhenci_id: this.cZhenci.zhenci_id,
          content_id: this.cContent.content_id,
          data: JSON.parse(JSON.stringify(this.cContent.data)),
          create_time: this.cContent["create_time"],
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        PostContents(this, params)
          .then((data: any) => {
            loading.close();
            this.$store.commit("updateCContent", data);
            this.$message.success("保存成功");
            this.redIndex = -1;
            this.$store.commit("updateIfCompose", false);
            // this.fetchContents();
            resolve(data);
          })
          .catch(() => {
            loading.close();
            reject();
          });
      }
    });
  }
  /**
   * @description 确认无效
   */
  private querenwuxiao() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
        patient_id: this.patient.patient_id,
        zhenci_id: this.cZhenci.zhenci_id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostPhoneInvalid(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("确认无效成功");
        this.refreshData();
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 开始提交
   */
  private startTijiao() {
    // 判断必填量表是否都填写
    let arr: any = [];
    this.interviews.forEach((element: any) => {
      element.templates.forEach((ele: any) => {
        if (ele.is_required && ele.status === "0") {
          arr.push(ele["名称"]);
        }
      });
    });
    let text = "";
    const role = this.user["role"]["名称"];
    if (role === "研究者" || role === "主要研究者") {
      if (arr.length > 0) {
        this.$alert(
          `${arr.join("、")}为必填量表，请完善必填字段后再提交。`,
          "提交",
          {
            customClass: "commonConfirm",
            confirmButtonText: "确定",
          }
        )
          .then(() => {})
          .catch(() => {});
      } else {
        this.$confirm(
          "您确认提交该访视数据么？提交后该访视内容将不能再修改，须待质量审核员审核。",
          "提交",
          {
            customClass: "commonConfirm",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }
        )
          .then(() => {
            this.tijiaoZhenci();
          })
          .catch(() => {
            console.log("否");
          });
      }
    } else if (role === "质量审核员" || role === "监查员") {
      if (role === "质量审核员") {
        text = "研究者/主要研究者";
      } else if (role === "监查员") {
        text = "质量审核员";
      }
      this.$confirm(
        `您务必认真审核后提交，提交后质疑的内容将全部发送给${text}。`,
        "审核提交",
        {
          customClass: "commonConfirm",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.tijiaoZhenci();
        })
        .catch(() => {
          console.log("否");
        });
    }
  }
  /**
   * @description 提交
   */
  private tijiaoZhenci() {
    const params: any = {
      zhenci_id: this.cZhenci["zhenci_id"],
      project_id: this.center.project_id,
      patient_id: this.patient.patient_id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    TijiaoShenhe(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.$message.success("提交成功");
        this.refreshData();
      })
      .catch(() => {
        loading.close();
      });
  }
  // 刷新数据，诊次状态改变时需要调用
  private refreshData() {
    // 刷新左边诊次列表
    this.getZhenciList().then(() => {
      // 更新当前诊次内容
      this.zhencis.forEach((ele: any) => {
        if (ele.zhenci_id === this.cZhenci.zhenci_id) {
          this.cZhenci = ele;
          this.$store.commit("updateCZhenci", this.cZhenci);
        }
      });
      // 更新右侧量表内容
      this.getLeftInterviews().then(() => {
        if (this.cTemplate.template_id) {
          this.interviews.forEach((element: any) => {
            element.templates.forEach((ele: any) => {
              if (ele.template_id == this.cTemplate.template_id) {
                this.cTemplate = ele;
                this.$store.commit("updateCTemplate", this.cTemplate);
              }
            });
          });
        }
        this.fetchContents();
      });
    });
  }

  /**
   * @description 复活
   */
  private fuhuo() {
    this.$confirm(
      "是否确定将该数据复活，复活后该数据即可复原，进入之前审核状态",
      {
        title: "审核通过",
        customClass: "commonConfirm",
        confirmButtonText: "是",
        cancelButtonText: "否",
      }
    )
      .then(() => {
        const params: any = {
          params: {
            project_id: this.center.project_id,
            patient_id: this.patient.patient_id,
            zhenci_id: this.cZhenci.zhenci_id,
          },
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        PostRelive(this, params, loading)
          .then((res: any) => {
            loading.close();
            this.$message.success("复活成功!");
            this.refreshData();
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch(() => {
        console.log("否");
      });
  }
  /**
   * @description 清空数据
   */
  private resetContent() {
    this.$confirm("是否确定清空改量表内容", {
      title: "清空内容",
      customClass: "commonConfirm",
      confirmButtonText: "是",
      cancelButtonText: "否",
    }).then(() => {
      const params: any = {
        project_id: this.center.project_id,
        patient_id: this.patient.patient_id,
        zhenci_id: this.cZhenci.zhenci_id,
        template_id: this.cTemplate.template_id,
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      ResetContent(this, params, loading).then((res: any) => {
        loading.close();
        this.$message.warning("清除成功！");
        this.$store.commit("updateIfCompose", false);
        this.getLeftInterviews().then(() => {
          this.fetchContents();
        });
      });
    });
  }
  /**
   * @description 打开质疑弹窗
   */
  private openZhiyi() {
    this.$store.commit("updateIfZhiyi", true);
  }
  /**
   * @description 确认审核通过
   */
  private confirmShenhetongguo(zhenci: any) {
    if (!this.user.role["名称"]) {
      return;
    }
    if (this.user.role["名称"] === "分中心负责人") {
      this.$confirm(
        "是否已经认真审核完毕，确定审核通过后，不能撤销。",
        "审核通过",
        {
          customClass: "commonConfirm",
          confirmButtonText: "是",
          cancelButtonText: "否",
        }
      )
        .then(() => {
          this.shenhetongguo();
        })
        .catch(() => {
          console.log("否");
        });
    } else if (this.user.role["名称"] === "专病负责人") {
      this.$confirm(
        "是否已经认真审核完毕，确定审核通过后，数据将锁定不能修改。",
        "审核通过",
        {
          customClass: "commonConfirm",
          confirmButtonText: "是",
          cancelButtonText: "否",
        }
      )
        .then(() => {
          this.shenhetongguo();
        })
        .catch(() => {
          console.log("否");
        });
    } else {
      this.shenhetongguo();
    }
  }
  private shenhetongguo() {
    const params: any = {
      project_id: this.center.project_id,
      patient_id: this.patient.patient_id,
      zhenci_id: this.cZhenci.zhenci_id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    Shenhetongguo(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.$message.success("审核通过成功！");
        this.$store.commit("updateIfZhiyi", false);
        this.refreshData();
      })
      .catch(() => {
        loading.close();
      });
  }
  private setLocalFilter() {
    // this.localFilter = this.filter;
  }
  /**
   * @description 判断当前用户在当前机构下能否显示某个功能
   */
  private canThisRoleAndStateUse(tool: any) {
    if (!this.user["role"]) {
      return false;
    }
    let canUse = false;
    const roleControl = this.$store.state.roleControl;
    const role = this.user["role"]["名称"];
    const status = this.cZhenci.status;
    const type = this.cZhenci.type;
    try {
      switch (tool) {
        case "添加诊次":
          // 身份有权限添加诊次
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            canUse = true;
            // 只能给自己创建的患者添加诊次
            // if (this.user.user_id === this.patient.user_id) {
            //   canUse = true;
            // }
          }
          break;
        case "编辑":
          // 身份有权限编辑
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 不能编辑电话访视，只能编辑诊次
            if (type !== "电话访视") {
              // 诊次状态为草稿可以编辑，或者诊次状态为没有经过打回的待审核状态，经过质量审核员操作后就不能操作了
              const hasShenhe = false;
              // 只能编辑自己创建患者的诊次
              if (status === "草稿") {
                canUse = true;
              }
              if (status === "待审核") {
                // if (
                //   this.cZhenci.audit_process &&
                //   this.cZhenci.audit_process.length > 1 &&
                //   this.cZhenci.audit_process[
                //     this.cZhenci.audit_process.length - 2
                //   ]["status"] === "质量审核员已打回"
                // ) {
                //   canUse = false;
                // } else {
                //   canUse = true;
                // }
              }
            }
          }
          break;
        case "删除":
          // 身份有权限删除
          if (roleControl["数据录入"].indexOf(role) !== -1) {
            // 诊次状态为草稿、质量审核员已打回的才可以删除
            if (status === "草稿" || status === "质量审核员已打回") {
              // 只能删除自己创建患者的诊次
              // if (this.user.user_id === this.patient.user_id) {
              //   canUse = true;
              // }
              canUse = true;
            }
          }
          break;
        case "确认无效":
          // 身份有权限确认无效
          if (roleControl["确认无效"].indexOf(role) !== -1) {
            // 只有电话访视能确认无效
            if (type === "电话访视") {
              // 只能确认无效自己创建患者的诊次和电话访视
              if (this.user.user_id === this.patient.user_id) {
                // 诊次状态为待确认的才可以确认无效
                if (status === "待确认" || status === "质量审核员已打回") {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "质疑":
          // 身份有权限质疑
          if (roleControl["质疑"].indexOf(role) !== -1) {
            // 当前是非质疑状态
            if (!this.ifZhiyi) {
              if (role === "质量审核员") {
                // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                if (
                  status === "待审核" ||
                  status === "监查员已打回" ||
                  (status === "质量审核员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              } else if (role === "监查员") {
                // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                if (
                  status === "分中心负责人通过" ||
                  (status === "监查员通过" &&
                    this.cZhenci.audit_process &&
                    this.cZhenci.audit_process.length > 0 &&
                    this.user.user_id ===
                      this.cZhenci.audit_process[
                        this.cZhenci.audit_process.length - 1
                      ]["recheck_id"])
                ) {
                  canUse = true;
                }
              }
            }
          }
          break;
        case "提交":
          // 身份有权限提交
          if (roleControl["提交"].indexOf(role) !== -1) {
            if (role === "研究者" || role === "主要研究者") {
              // 只能提交自己创建患者的诊次和电话访视
              // if (this.user.user_id === this.patient.user_id) {
              //   // 诊次状态为草稿、质量审核员已打回或者待确认
              // }
              if (
                status === "草稿" ||
                status === "待确认" ||
                status === "质量审核员已打回"
              ) {
                canUse = true;
              }
            } else if (role === "质量审核员" || role === "监查员") {
              if (this.ifZhiyi) {
                if (role === "质量审核员") {
                  // 诊次状态为待审核、监查员已打回或自己审核通过的（非其他质量审核员通过）
                  if (
                    status === "待审核" ||
                    status === "监查员已打回" ||
                    (status === "质量审核员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                } else if (role === "监查员") {
                  // 诊次状态为分中心负责人通过、自己审核通过的（非其他监查员通过）
                  if (
                    status === "分中心负责人通过" ||
                    (status === "监查员通过" &&
                      this.cZhenci.audit_process &&
                      this.cZhenci.audit_process.length > 0 &&
                      this.user.user_id ===
                        this.cZhenci.audit_process[
                          this.cZhenci.audit_process.length - 1
                        ]["recheck_id"])
                  ) {
                    canUse = true;
                  }
                }
              }
            }
          }
          break;
        case "审核通过":
          if (roleControl["审核通过"].indexOf(role) !== -1) {
            if (role === "质量审核员") {
              if (status === "待审核" || status === "监查员已打回") {
                canUse = true;
              }
            } else if (role === "监查员") {
              if (status === "分中心负责人通过") {
                canUse = true;
              }
            } else if (role === "分中心负责人") {
              if (status === "质量审核员通过") {
                canUse = true;
              }
            } else if (role === "专病负责人") {
              if (status === "监查员通过") {
                canUse = true;
              }
            }
          }
          break;
        case "复活":
          // 身份有权限复活诊次
          if (roleControl["复活"].indexOf(role) !== -1) {
            // 只能给失活状态的数据进行复活
            if (status === "失活") {
              canUse = true;
            }
          }
          break;
        case "查看监查痕迹":
          if (roleControl["监查痕迹"].indexOf(role) !== -1) {
            canUse = true;
          }
          break;
        case "打回草稿":
          if (roleControl["打回草稿"].indexOf(role) !== -1) {
            if (status === "待审核") {
              canUse = true;
            }
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }
    return canUse;
  }
  private delTimu(index: any) {
    let d = JSON.parse(JSON.stringify(this.cContent));
    d.data.splice(index, 1);
    this.$store.commit("updateCContent", d);
    this.$forceUpdate();
  }
  private updateWholeTem(e: any) {
    let d = JSON.parse(JSON.stringify(this.cContent));
    d.data = e;
    this.$store.commit("updateCContent", d);
    this.$forceUpdate();
  }
  private updateContent(e: any) {
    let d = this.cContent;
    d.data[e.index] = e.data;
    //计算BMI，量表顺序只能是身高，体重，BMI
    if (e.data.title === "身高") {
      const shengao = d.data[e.index].value / 100;
      const tizhong = d.data[e.index + 1].value;
      d.data[e.index + 2].value = (tizhong / (shengao * shengao)).toFixed(2);
    } else if (e.data.title === "体重") {
      const shengao = d.data[e.index - 1].value / 100;
      const tizhong = d.data[e.index].value;
      d.data[e.index + 1].value = (tizhong / (shengao * shengao)).toFixed(2);
    }
    // 处理民族医特殊计分
    if (
      this.cTemplate["名称"] == "疗效评价" &&
      (e.data.title == "治疗前白斑VASI评分" ||
        e.data.title == "治疗后白斑VASI评分" ||
        e.data.title == "治疗前维吾尔医证候症状评分" ||
        e.data.title == "治疗后维吾尔医证候症状评分" ||
        e.data.title == "治疗前PASI评分" ||
        e.data.title == "治疗后PASI评分")
    ) {
      if (e.index === 1 || e.index === 2) {
        if (d.data[1].value && d.data[2].value) {
          d.data[3].value =
            (
              ((d.data[1].value - d.data[2].value) / d.data[1].value) *
              100
            ).toFixed(0) + "%";
        } else {
          d.data[3].value = "";
        }
      }
      if (e.index === 6 || e.index === 7) {
        if (d.data[6].value && d.data[7].value) {
          d.data[8].value =
            (
              ((d.data[6].value - d.data[7].value) / d.data[6].value) *
              100
            ).toFixed(0) + "%";
        } else {
          d.data[8].value = "";
        }
      }
    }
    this.$store.commit("updateCContent", d);
    this.$forceUpdate();
  }
  private updateData(e: any) {
    // this.$store.commit("updateCContent", this.cContent);
    // this.$forceUpdate();
  }
  private jishu(e: any) {
    // 这是广东民族医特有的
    let d = JSON.parse(JSON.stringify(this.cContent));
    let target: any = {};
    d.data.forEach((ele: any) => {
      if (ele.title == "28个关节肿痛计数") {
        target = ele;
      }
    });
    let num: any = 0;
    if (d.data[e].title === "压痛关节数") {
      target.value.forEach((element: any) => {
        element.forEach((ele: any) => {
          if (ele.type == "多选") {
            if (ele.value.indexOf("压痛") !== -1) {
              num += 1;
            }
          }
        });
      });
    } else if (d.data[e].title === "肿胀关节数") {
      target.value.forEach((element: any) => {
        element.forEach((ele: any) => {
          if (ele.type == "多选") {
            if (ele.value.indexOf("肿胀") !== -1) {
              num += 1;
            }
          }
        });
      });
    }
    d.data[e].value = num;
    this.$store.commit("updateCContent", d);
    this.$forceUpdate();
  }
  /**
   * @description 获得诊次列表
   */
  private getZhenciList() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          project_id: this.center.project_id,
          group_id: this.center.group_id,
          patient_id: this.patient.patient_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      GetZhenciList(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.zhencis = data.data;
          resolve(data);
        })
        .catch(() => {
          loading.close();
          reject();
        });
    });
  }

  /**
   * @description 获得左侧模板
   */
  private getLeftInterviews() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
          project_id: this.center.project_id,
          group_id: this.center.group_id,
          zhenci_id: this.cZhenci.zhenci_id,
          type: this.cZhenci.type,
        },
      };
      if (this.source === "shujuchi") {
        params.params.special = true;
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      LeftInterviews(this, params)
        .then((data: any) => {
          loading.close();
          data.forEach((i: any) => {
            i["ifShowTemplate"] = true;
          });
          this.interviews = data;
          resolve(data);
        })
        .catch((e) => {
          loading.close();
          reject();
        });
    });
  }
  /**
   * @description 选择一个左侧模板
   */
  private selectTemplate(template: any) {
    if (!this.ifCompose) {
      this.cTemplate = template;
      this.$store.commit("updateCTemplate", this.cTemplate);
      this.fetchTemplate();
      if (this.clickBtn == "添加诊次") {
        this.$store.commit("updateIfCompose", true);
      }
      setTimeout(() => {
        this.clickBtn == "";
      }, 1000);
    } else {
      this.saveConfirmType = "量表";
      this.noSaveText = "不保存，切换";
      this.target = JSON.parse(JSON.stringify(template));
      this.ifShowSaveConfirm = true;
    }
  }
  /**
   * @description 获取右侧详细模板信息
   */
  private fetchTemplate() {
    this.redIndex = -1;
    const type = this.cTemplate["名称"];
    if (type === "舌图数据") {
      const params = {
        params: {
          patient_id: this.patient.patient_id,
          zhenci_id: this.cZhenci.zhenci_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getTongue(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$store.commit("updateCContent", res);
        })
        .catch((e) => {
          loading.close();
        });
    } else if (type === "面图数据") {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
          zhenci_id: this.cZhenci.zhenci_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getFaceAnalysisData(this, params)
        .then((data: any) => {
          loading.close();
          this.$store.commit("updateCContent", data);
        })
        .catch((e: any) => {
          loading.close();
        });
    } else if (type === "脉图数据") {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
          zhenci_id: this.cZhenci.zhenci_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getPulseAnalysisData(this, params)
        .then((data: any) => {
          loading.close();
          this.$store.commit("updateCContent", data);
        })
        .catch((e: any) => {
          loading.close();
        });
    } else if (type === "目图数据") {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
          zhenci_id: this.cZhenci.zhenci_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      GetZhenciEyeData(this, params)
        .then((data: any) => {
          loading.close();
          if (!data.eye_id) {
            data = {
              source: this.center["项目名称"],
              project_id: this.center.project_id,
              source_type: 0,
              patient_id: this.patient.patient_id,
              zhenci_id: this.cZhenci.zhenci_id,
              l_eye_image: [],
              r_eye_image: [],
            };
            this.$store.commit("updateCContent", data);
          } else {
            this.$store.commit("updateCContent", data);
          }
        })
        .catch((e: any) => {
          loading.close();
        });
    } else if (type === "手掌数据") {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
          zhenci_id: this.cZhenci.zhenci_id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      GetZhenciPalmData(this, params)
        .then((data: any) => {
          loading.close();
          if (!data.palm_id) {
            data = {
              source: this.center["项目名称"],
              project_id: this.center.project_id,
              source_type: 0,
              patient_id: this.patient.patient_id,
              zhenci_id: this.cZhenci.zhenci_id,
              l_palm_image: [],
              r_palm_image: [],
            };
            this.$store.commit("updateCContent", data);
          } else {
            this.$store.commit("updateCContent", data);
          }
        })
        .catch((e: any) => {
          loading.close();
        });
    } else {
      this.fetchContents();
      //   const params: any = {
      //     params: {
      //       template_id: this.cTemplate.template_id,
      //     },
      //   };
      //   GetTemplates(this, params).then((data: any) => {
      //     this.templates = data;
      //     this.fetchContents();
      //   });
    }
  }
  /**
   * @description 获取右侧数据详情
   */
  private fetchContents() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        project_id: this.center.project_id,
        group_id: this.center.group_id,
        zhenci_id: this.cZhenci.zhenci_id,
        template_id: this.cTemplate.template_id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    this.ifShow = false;
    GetContents(this, params)
      .then((data: any) => {
        this.ifShow = true;
        loading.close();
        this.$store.commit("updateCContent", data);
      })
      .catch((e) => {
        this.ifShow = true;
        loading.close();
      });
  }
  /**
   * @description 切换当前诊次
   */
  private changeZhenci(zhenci: any, zhenciIndex: any) {
    if (!this.ifCompose) {
      this.$router.replace({
        path: "/main/storehouse/case/detail",
        query: {
          id: zhenci.zhenci_id,
        },
      });
      this.cZhenci = zhenci;
      this.$store.commit("updateCZhenci", this.cZhenci);
      this.cTemplate = {};
      this.$store.commit("updateCTemplate", this.cTemplate);
      this.$store.commit("updateCContent", {});
      this.getLeftInterviews().then(() => {
        this.selectBingli();
      });
    } else {
      this.saveConfirmType = "诊次";
      this.noSaveText = "不保存，切换";
      this.ifShowSaveConfirm = true;
      this.target = zhenci;
    }
  }
  private getUser() {
    // 获取当前用户信息
    const params = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetCurrentUserData(this, params).then((res: any) => {
      this.$store.commit("updateUser", res);
    });
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.saveConfirmType = "离开";
      this.noSaveText = "不保存，离开";
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private mounted() {
    this.$store.commit("updateCZhenci", {});
    this.$store.commit("updateCContent", {});
    this.$store.commit("updateCTemplate", {});
    // 获取当前用户信息
    this.getUser();
    // 避免自动登录报错
    const d = localStorage.getItem("patient") || "";
    if (d) {
      this.$store.commit("updatePatient", JSON.parse(d));
    }
    if (this.$route.query && this.$route.query.id) {
      this.zhenci_id = this.$route.query.id;
    }
    if (this.$route.query && this.$route.query.source) {
      this.source = this.$route.query.source;
    }
    this.getZhenciList().then(() => {
      // 根据诊次id选中当前诊次
      this.zhencis.forEach((ele: any) => {
        if (ele.zhenci_id === this.zhenci_id) {
          this.cZhenci = ele;
          this.$store.commit("updateCZhenci", this.cZhenci);
          this.getLeftInterviews().then(() => {
            this.selectBingli();
          });
        }
      });
    });
  }
}
